import { styled } from 'src/gatsby-theme-stitches/config'

export const PresentationContainer = styled('section', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  background: '#FF5197',
  overflow: 'hidden',

  '@lg': {
    height: '62vh',
  },

  '@sm': {
    flexDirection: 'column',
  },
})

export const FirstFlowerDecalque = styled('div', {
  '@lg': {
    position: 'absolute',
    bottom: '-0.75rem',
    left: '0',
    width: '22%',
  },

  '@sm': {
    margin: '0 auto',
    width: '65%',
  },

  img: {
    width: '100%',
  },
})

export const Title = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',

  '@sm': {
    margin: '0 auto 1rem',
    width: '100%',
  },

  '@lg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  h1: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '3.3125rem',
    fontWeight: '500',
    lineHeight: '4.5625rem',
    textAlign: 'center',
    color: '#632980',

    '@sm': {
      fontSize: '2rem',
      lineHeight: '2.875rem',
    },

    strong: {
      fontSize: '3.875rem',
      lineHeight: '5.625rem',
      fontWeight: '700',
      color: '#FFF',

      '@sm': {
        fontSize: '2.3125rem',
        lineHeight: '3.375rem',
      },
    },
  },

  h2: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '3.3125rem',
    fontWeight: '600',
    lineHeight: '3.3125rem',
    textAlign: 'center',
    color: '#632980',

    '@sm': {
      fontSize: '2.375rem',
      lineHeight: '3.4375rem',
    },
  },
})

export const SecondFlowerDecalque = styled('div', {
  '@lg': {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '25%',
  },

  img: {
    width: '100%',
  },
})

export const WhatIsTheProgramContainer = styled('section', {
  width: '100%',
  background: '#FF5197',
})

export const WhatIsTheProgramWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.875rem',
  width: '95%',
  padding: '0 50px 0 70px',
  background: '#FF0047',

  '@sm': {
    flexDirection: 'column',
    gap: '0',
    width: '100%',
    padding: '0',
    background: 'initial',
  },
})

export const WhatIsTheProgramImage = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flex: '1',

  '@sm': {
    justifyContent: 'flex-start',
    width: '100%',
  },

  img: {
    '@sm': {
      width: '70%',
    },
  },
})

export const WhatIsTheProgramText = styled('div', {
  width: '60%',
  background: '#FF0047',

  '@sm': {
    width: '100%',
    background: '#FF0047',
    padding: '1.875rem 1.5rem',
  },

  h3: {
    marginBottom: '1.375rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.6875rem',
    fontWeight: '500',
    lineHeight: '3.5rem',
    color: '#FF903D',

    '@sm': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.125rem',
      lineHeight: '2.825rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontWeight: '700',
      color: '#FFF',
    },
  },

  p: {
    marginBottom: '1.2rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.6875rem',
    color: '#FFF',

    '@sm': {
      marginBottom: '0',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})
