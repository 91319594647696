import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import EventPageView from 'src/components/common/EventPageView'
import Presentation from 'src/components/liguecancer/Presentation'
import WhichPhoneNumber from 'src/components/liguecancer/WhichPhoneNumber'
import QuestionService from 'src/components/liguecancer/QuestionService'
import Guidelines from 'src/components/liguecancer/Guidelines'
import PersonalizedService from 'src/components/liguecancer/PersonalizedService'
import InstituteIcons from 'src/components/liguecancer/InstituteIcons'
import FloatingPhoneNumber from 'src/components/liguecancer/FloatingPhoneNumber'
import type { InstitutoAvonLigueCancerPageQueryQuery } from 'src/pages/liguecancer/__generated__/InstitutoAvonLigueCancerPageQuery.graphql'

type Props = PageProps<InstitutoAvonLigueCancerPageQueryQuery>

const Above: FC<Props> = ({ data: { cmsInstitutionalPage } }) => {
  if (!cmsInstitutionalPage) {
    return null
  }

  return (
    <div>
      <EventPageView
        type="OTHER"
        title={cmsInstitutionalPage?.seo?.siteMetadataWithSlug?.title}
      />
      <Presentation data={cmsInstitutionalPage} />
      <WhichPhoneNumber data={cmsInstitutionalPage} />
      <QuestionService data={cmsInstitutionalPage} />
      <Guidelines data={cmsInstitutionalPage} />
      <PersonalizedService data={cmsInstitutionalPage} />
      <InstituteIcons />
      <FloatingPhoneNumber />
    </div>
  )
}

export default Above
