import { styled } from 'src/gatsby-theme-stitches/config'

export const PersonalizedServiceContainer = styled('section', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.875rem',
  width: '100%',
  paddingLeft: '5rem',
  background: '#FF5197',

  '@sm': {
    flexDirection: 'column',
    gap: '0',
    paddingLeft: '0',
  },
})

export const PersonalizedServiceText = styled('div', {
  width: '48%',

  '@sm': {
    marginTop: '1.5625rem',
    position: 'relative',
    order: '2',
    width: '90%',
    zIndex: 1,
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '3.125rem',
    fontWeight: '500',
    lineHeight: '4.5625rem',
    color: '#FFF',

    '@sm': {
      fontSize: '2.125rem',
      lineHeight: '2.9rem',
    },
  },

  p: {
    marginTop: '1.25rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const PersonalizedServiceImage = styled('div', {
  position: 'relative',
  flex: 1,

  '@sm': {
    top: '1rem',
    order: '1',
  },

  img: {
    width: '100%',
  },
})
