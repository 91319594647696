import React from 'react'
import RichText from 'src/components/ui/RichText'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const Guidelines = ({ data }: Props) => (
  <S.GuidelinesContainer>
    <S.GuidelinesWrapper>
      <S.GuidelinesFirstImage>
        <ResponsiveImage
          desktop={data.sections[12].data.desktop.srcSet}
          mobile={data.sections[12].data.mobile.srcSet}
          alt={data.sections[12].data.alt}
        />
      </S.GuidelinesFirstImage>
      <S.GuidelinesText>
        <RichText richText={data.sections[13].data.content} />
        <RichText richText={data.sections[14].data.content} />
        <RichText richText={data.sections[15].data.content} />
      </S.GuidelinesText>
      <S.GuidelinesSecondImage>
        <ResponsiveImage
          desktop={data.sections[16].data.desktop.srcSet}
          mobile={data.sections[16].data.mobile.srcSet}
          alt={data.sections[16].data.alt}
        />
      </S.GuidelinesSecondImage>
    </S.GuidelinesWrapper>
    <S.GuidelinesDisclosedInformationsWrapper>
      <RichText richText={data.sections[17].data.content} />
      <RichText richText={data.sections[18].data.content} />
    </S.GuidelinesDisclosedInformationsWrapper>
  </S.GuidelinesContainer>
)

export default Guidelines
