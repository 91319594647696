import { styled } from 'src/gatsby-theme-stitches/config'

export const InstituteContainer = styled('section', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5rem',
  width: '100%',
  background: '#FF5197',
  padding: '3.125rem 0',

  '@sm': {
    gap: '1rem',
    flexDirection: 'column',
  },

  svg: {
    width: '13.125rem',

    '@lg': {
      width: '18.75rem',
    },

    path: {
      fill: '#FFF',
    },
  },
})
