import React from 'react'
import RichText from 'src/components/ui/RichText'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const QuestionService = ({ data }: Props) => (
  <S.QuestionServiceContainer>
    <S.QuestionServiceWrapper>
      <S.QuestionServiceImage>
        <ResponsiveImage
          desktop={data.sections[9].data.desktop.srcSet}
          mobile={data.sections[9].data.mobile.srcSet}
          alt={data.sections[9].data.alt}
        />
      </S.QuestionServiceImage>

      <S.QuestionServiceText>
        <RichText richText={data.sections[10].data.content} />
        <RichText richText={data.sections[11].data.content} />
      </S.QuestionServiceText>
    </S.QuestionServiceWrapper>
  </S.QuestionServiceContainer>
)

export default QuestionService
