import React from 'react'
import { AvonInstituteIcon, OncoguideIcon } from 'src/components/icons'

import * as S from './styles'

const InstituteIcon = () => {
  return (
    <S.InstituteContainer>
      <a
        href="https://institutoavon.org.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AvonInstituteIcon />
      </a>
      <a
        href="http://www.oncoguia.org.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        <OncoguideIcon />
      </a>
    </S.InstituteContainer>
  )
}

export default InstituteIcon
