import React from 'react'
import RichText from 'src/components/ui/RichText'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'
import { Hidden } from 'src/components/ui/Hidden'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const Presentation = ({ data }: Props) => (
  <>
    <S.PresentationContainer>
      <S.FirstFlowerDecalque>
        <ResponsiveImage
          desktop={data.sections[0].data.desktop.srcSet}
          mobile={data.sections[0].data.mobile.srcSet}
          alt={data.sections[0].data.alt}
        />
      </S.FirstFlowerDecalque>
      <S.Title>
        <RichText richText={data.sections[1].data.content} />
      </S.Title>

      <Hidden device="mobile">
        <S.SecondFlowerDecalque>
          <img
            src={data.sections[2].data.desktop.srcSet}
            alt={data.sections[2].data.alt}
          />
        </S.SecondFlowerDecalque>
      </Hidden>
    </S.PresentationContainer>
    <S.WhatIsTheProgramContainer>
      <S.WhatIsTheProgramWrapper>
        <S.WhatIsTheProgramImage>
          <ResponsiveImage
            desktop={data.sections[3].data.desktop.srcSet}
            mobile={data.sections[3].data.mobile.srcSet}
            alt={data.sections[3].data.alt}
          />
        </S.WhatIsTheProgramImage>
        <S.WhatIsTheProgramText>
          <RichText richText={data.sections[4].data.content} />
          <RichText richText={data.sections[5].data.content} />
        </S.WhatIsTheProgramText>
      </S.WhatIsTheProgramWrapper>
    </S.WhatIsTheProgramContainer>
  </>
)

export default Presentation
