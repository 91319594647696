import { styled } from 'src/gatsby-theme-stitches/config'

export const GuidelinesContainer = styled('section', {
  position: 'relative',
  width: '100%',
  padding: '4rem 0 8rem',
  background: '#FF5197',

  '@sm': {
    padding: '0',
  },
})

export const GuidelinesWrapper = styled('div', {
  width: '100%',
  display: 'flex',

  '@sm': {
    alignItems: 'center',
    flexDirection: 'column',
  },
})

export const GuidelinesFirstImage = styled('div', {
  '@sm': {
    display: 'flex',
    justifyContent: 'center',
  },

  '@lg': {
    position: 'absolute',
    bottom: '5rem',
    left: '0',
  },

  img: {
    display: 'flex',
  },
})

export const GuidelinesText = styled('div', {
  margin: '0 auto',
  width: '70%',
  padding: '3.125rem 5.5rem',
  background: '#FFF',

  '> div': {
    '&:last-of-type': {
      p: {
        fontFamily: 'Tusker Grotesk',
        fontSize: '1.2rem',
        fontWeight: '300',
        color: '#FF0049',

        '@sm': {
          fontSize: '1rem',
        },
      },
    },
  },

  '@sm': {
    width: '100%',
    padding: '3.125rem 1rem',
  },

  '@lg': {
    position: 'relative',
    zIndex: 1,
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.5rem',
    fontWeight: '500',
    lineHeight: '3.5rem',
    textAlign: 'center',
    color: '#632980',

    '&:first-of-type': {
      fontSize: '2.4rem',
      fontWeight: '900',
      color: '$primary',

      '@sm': {
        fontSize: '1.3rem',
      },
    },

    strong: {
      fontWeight: '500',
    },

    '@sm': {
      fontSize: '1.4rem',
      textAlign: 'initial',
      lineHeight: '2rem',
    },
  },

  h4: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    fontWeight: '700',
    textAlign: 'center',
    color: '#FF0049',
  },

  p: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#000',

    '@sm': {
      marginTop: '1.5rem',
      fontSize: '1.25rem',
      textAlign: 'initial',
      lineHeight: '1.5rem',
    },

    '@lg': {
      padding: '0 3rem',
      marginTop: '2rem',
    },

    strong: {
      color: '#632980',
      fontWeight: '100',
    },
  },

  ul: {
    listStyle: 'disc',

    '@lg': {
      padding: '0 3rem',
      marginTop: '2.5rem',
    },

    '@sm': {
      margin: '0 auto',
      width: '90%',
    },

    li: {
      marginBottom: '1.25rem',
      fontFamily: 'Lato',
      fontSize: '1.4375rem',
      lineHeight: '1.75rem',
      color: '#000',

      '@sm': {
        marginTop: '1.5rem',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
  },
})

export const GuidelinesSecondImage = styled('div', {
  '@lg': {
    position: 'absolute',
    top: '0.625rem',
    right: '0',
  },

  img: {
    position: 'relative',
  },
})

export const GuidelinesDisclosedInformationsWrapper = styled('div', {
  margin: '4rem auto 0',
  width: '70%',

  '@sm': {
    margin: '2rem auto 0',
    width: '90%',
  },

  h3: {
    marginBottom: '1.25rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.5rem',
    fontWeight: '500',
    textAlign: 'center',
    color: '#FFF',

    '@sm': {
      marginBottom: '2rem',
      fontSize: '2.125rem',
      lineHeight: '3rem',
    },

    strong: {
      padding: '0.625rem',
      background: '#FF973C',
      fontWeight: '500',
      color: '#632980',
    },
  },

  p: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    textAlign: 'center',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      textAlign: 'initial',
      lineHeight: '1.5rem',
    },

    '@lg': {
      padding: '0 5.625rem',
    },

    strong: {
      padding: '0.2rem 0.625rem',
      background: '#FF973C',
      fontWeight: '900',
      color: '#632980',
    },
  },
})
