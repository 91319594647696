import { styled } from 'src/gatsby-theme-stitches/config'

export const WhichPhoneNumberContainer = styled('section', {
  width: '100%',
  background: '#FF5197',

  '@sm': {
    padding: '0.75rem 0 1.25rem',
  },
})

export const WhichPhoneNumberWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  width: '85%',
  margin: '0 auto',

  '@sm': {
    gap: '0',
    flexDirection: 'column',
  },
})

export const WhichPhoneNumberText = styled('div', {
  width: '44%',

  '@sm': {
    marginTop: '1.5rem',
    order: 2,
    width: '100%',
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.6875rem',
    fontWeight: '500',
    lineHeight: '3.5rem',
    color: '#FFF',

    '@sm': {
      fontSize: '2.125rem',
      lineHeight: '3.0625rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontSize: '2.75rem',
      fontWeight: '700',
      color: '#632980',

      '@sm': {
        fontSize: '2.125rem',
        lineHeight: '3.0625rem',
      },
    },
  },

  h4: {
    margin: '0.875rem 0',

    a: {
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '3.6875rem',
      lineHeight: '3.6875rem',
      textDecoration: 'none',
      color: '#FFF',

      '&:hover': {
        textDecoration: 'underline',
      },

      '@sm': {
        fontSize: '2.8125rem',
        lineHeight: '2rem',
      },
    },
  },

  a: {
    textDecoration: 'none',
  },

  p: {
    marginBottom: '0.75rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      marginBottom: '1.5rem',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const WhichPhoneNumberImage = styled('div', {
  flex: 1,

  '@sm': {
    order: 1,
  },

  img: {
    width: '100%',
  },
})
