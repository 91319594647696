import { styled } from 'src/gatsby-theme-stitches/config'

export const QuestionServiceContainer = styled('section', {
  width: '100%',
  background: '#FF5197',
})

export const QuestionServiceWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  width: '80%',
  margin: '0 auto',

  '@sm': {
    gap: '0',
    flexDirection: 'column',
    width: '88%',
  },
})

export const QuestionServiceText = styled('div', {
  width: '62%',

  '@sm': {
    order: 2,
    width: '100%',
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.9375rem',
    fontWeight: '700',
    lineHeight: '3.625rem',
    color: '#632980',

    '@sm': {
      fontSize: '2rem',
      lineHeight: '2.75rem',
    },
  },

  h4: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '1.875rem',
    fontWeight: '500',
    lineHeight: '3.625rem',
    color: '#632980',

    '@sm': {
      fontSize: '1.1875rem',
      lineHeight: '1.875rem',
    },
  },

  h5: {
    margin: '0',
    fontFamily: 'Tusker Grotesk',
    fontWeight: 500,
    fontSize: '3.1875rem',
    lineHeight: '3.4375rem',
    color: '#FFF',

    '@sm': {
      fontSize: '2rem',
      lineHeight: '2.75rem',
    },
  },

  p: {
    marginTop: '0.75rem',
    width: ['initial', '62%'],
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const QuestionServiceImage = styled('div', {
  flex: 1,

  '@sm': {
    order: 1,
    marginBottom: '2rem',
  },

  img: {
    width: '100%',
  },
})
