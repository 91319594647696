import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'

import * as S from './styles'

const FloatingPhoneNumber = () => {
  return (
    <a href="tel:0800 773 1666" target="_blank" rel="noreferrer">
      <S.FloatingPhoneNumberContainer>
        <Icon
          name="outlined-communication-phoneringing"
          size="semi"
          color="surface"
        />
      </S.FloatingPhoneNumberContainer>
    </a>
  )
}

export default FloatingPhoneNumber
