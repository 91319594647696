import React from 'react'
import RichText from 'src/components/ui/RichText'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const PersonalizedService = ({ data }: Props) => (
  <S.PersonalizedServiceContainer>
    <S.PersonalizedServiceText>
      <RichText richText={data.sections[19].data.content} />
      <RichText richText={data.sections[20].data.content} />
    </S.PersonalizedServiceText>
    <S.PersonalizedServiceImage>
      <ResponsiveImage
        desktop={data.sections[21].data.desktop.srcSet}
        mobile={data.sections[21].data.mobile.srcSet}
        alt={data.sections[21].data.alt}
      />
    </S.PersonalizedServiceImage>
  </S.PersonalizedServiceContainer>
)

export default PersonalizedService
