import { styled } from 'src/gatsby-theme-stitches/config'

export const FloatingPhoneNumberContainer = styled('button', {
  position: 'fixed',
  bottom: '8.5rem',
  right: '0.3125rem',
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  background: '#FF0049',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  zIndex: 1,
})
