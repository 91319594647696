import React from 'react'
import RichText from 'src/components/ui/RichText'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const WhichNumberPhone = ({ data }: Props) => (
  <S.WhichPhoneNumberContainer>
    <S.WhichPhoneNumberWrapper>
      <S.WhichPhoneNumberText>
        <RichText richText={data.sections[6].data.content} />
        <RichText richText={data.sections[7].data.content} />
      </S.WhichPhoneNumberText>

      <S.WhichPhoneNumberImage>
        <ResponsiveImage
          desktop={data.sections[8].data.desktop.srcSet}
          mobile={data.sections[8].data.mobile.srcSet}
          alt={data.sections[8].data.alt}
        />
      </S.WhichPhoneNumberImage>
    </S.WhichPhoneNumberWrapper>
  </S.WhichPhoneNumberContainer>
)

export default WhichNumberPhone
